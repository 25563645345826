document.addEventListener("DOMContentLoaded", function () {
	var widthSM = window.matchMedia("(min-width: 768px)");
	var widthMD = window.matchMedia("(min-width: 1024px)");
	
	var iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	
	if (iOS) {
		document.addEventListener('gesturestart', function (e) {
			e.preventDefault();
		});
	}
	
	var $windowIsLoaded = false;
	var loader = document.querySelector('.js-loader');
	
	if ($('.js-loader').length) {
	
		setTimeout(function () {
			$windowIsLoaded = true;
			$('.js-loader').fadeOut(600);
		}, 1200);
	}
	// POLYFILLS
	// Object fit IE
	$(function () { objectFitImages() });
	var mainSlider;
	
	function sliderInit() {
		var sliderDirection = 'horizontal';
	
		if (widthSM.matches) {
			sliderDirection = 'vertical';
		} else {
			sliderDirection = 'horizontal';
		}
	
		mainSlider = new Swiper('.js-main-slider', {
			direction: sliderDirection,
			spaceBetween: 0,
			slidesPerView: 1,
			watchOverflow: true,
			progressbarOpposite: true,
			speed: 850,
			simulateTouch: false,
			effect: 'fade',
			fadeEffect: {
				crossFade: true
			},
			mousewheel: {
				forceToAxis: false,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: true,
				pageUpDown: true,
			},
			hashNavigation: {
				watchState: true,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
				hideOnClick: false
			},
			on: {
				hashSet: function() {
					var hash = window.location.hash.substr(1);
					var pageNavLink = $('.page-nav').find('[href="#' + hash + '"]');
					pageNavLink.addClass('page-nav__link--active')
					pageNavLink.parent().siblings().find('.page-nav__link').removeClass('page-nav__link--active');
				},
				hashChange: function () {
					var hash = window.location.hash.substr(1);
					var pageNavLink = $('.page-nav').find('[href="#' + hash + '"]');
					if (!pageNavLink.length) {
						mainSlider.slideTo(0);
					}
				}
			}
		});
	}
	
	sliderInit();
	
	var resizeTimer;
	
	$(window).on('resize', function (e) {
		clearTimeout(resizeTimer);
		resizeTimer = setTimeout(function () {
			if (widthMD.matches) {
				mainSlider.changeDirection('vertical', true);
			} else {
				mainSlider.changeDirection('horizontal', true);
			}
		}, 250);
	});
	var pageNav = $('.page-nav');
	var menuToggle = $('.js-menu-toggle');
	var bodyTarget = document.querySelector('.page-nav');
	
	function openMenu() {
		bodyScrollLock.disableBodyScroll(bodyTarget);
		menuToggle.addClass('is-active');
		pageNav.fadeIn(300);
	}
	
	function closeMenu() {
		bodyScrollLock.enableBodyScroll(bodyTarget);
		menuToggle.removeClass('is-active');
		pageNav.fadeOut(300);
	}
	
	menuToggle.on('click', function () {
		var $this = $(this);
	
		$this.toggleClass('is-active');
	
		if ($this.hasClass('is-active')) {
			openMenu();
		} else {
			closeMenu();
		}
	});
	
	$('.js-page-nav-toggle').on('click', function () {
		closeMenu();
		menuToggle.removeClass('is-active');
	});
	$('.js-modal-open').on('click', function (event) {
		event.preventDefault();
		var $source = $(this).attr('data-src');
	
		$.fancybox.open({
			src: $source,
			slideClass: 'modal-wrapper',
			defaultType: 'inline',
			autoFocus: true,
			backFocus: true,
			touch: false,
			clickSlide: false,
			trapFocus: true,
			toolbar   : false,
			smallBtn: false,
			gutter: 0,
		});
	});
	
	$('.js-modal-close').on('click', function() {
		var modal = $('.modal');
		$.fancybox.close();
	
	
		setTimeout(function () {
			modal.find('form').get(0).reset();
			modal.find('.form-input, .form-textarea').removeClass('invalid');
		}, 500);
	});
	function initYandexMap() {
		ymaps.ready(function () {
			var location = $('.map');
			var locationLat = 55.050592;
			var locationLong = 82.913090;
			var $balloon = 'img/icons/icon-balloon.svg';
	
	
			if (location.attr('data-lat') !== '' && location.attr('data-long') !== '') {
				locationLat = JSON.parse(location.attr('data-lat'));
				locationLong = JSON.parse(location.attr('data-long'));
			}
	
			var myMap = new ymaps.Map('map', {
				center: [locationLat, locationLong],
				zoom: 13,
			}, {
				searchControlProvider: 'yandex#search'
			});
	
			var $mainLocation = myMap.geoObjects.add(new ymaps.Placemark([locationLat, locationLong], {}, {
				iconLayout: 'default#image',
				iconImageHref: $balloon,
				iconImageSize: [25, 32],
			}));
		});
	}
	
	if ($("#map").length > 0) {
		initYandexMap();
	}
});